<template>
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <b-img
        :src="appLogoImage"
        class="logo-content"
        alt="logo"
      />
      <h2 class="brand-text text-primary mb-0">
        {{ appName }}
      </h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Logging Out, please wait.
        </h2>
        <b-img
          fluid
          :src="imgUrl"
          alt="Not authorized page"
        />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { BLink, BImg } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
import store from '@/store/index'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BLink, BImg,
  },
  data() {
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
      downImg: require('@/assets/images/pages/not-authorized.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/not-authorized-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  created() {
    this.logout()
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token

      this.$http.post('/logout')
        .then(resp => {
          if (resp.data.success === true) {
            localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)

            // Remove userData from localStorage
            localStorage.removeItem('userToken')
            localStorage.removeItem('userData')
            localStorage.removeItem('userId')
            localStorage.removeItem('userRole')

            // Reset ability
            this.$ability.update(initialAbility)

            // Redirect to logout page
            setTimeout(() => {
              // Redirect to login page
              this.$router.push({ name: 'auth-login' })
            }, 2000)
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)

            // Remove userData from localStorage
            localStorage.removeItem('userToken')
            localStorage.removeItem('userData')
            localStorage.removeItem('userId')
            localStorage.removeItem('userRole')

            // Reset ability
            this.$ability.update(initialAbility)

            setTimeout(() => {
              // Redirect to login page
              this.$router.push({ name: 'auth-login' })
            }, 1000)
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
